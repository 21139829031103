import React, {useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Card, CardActions,
    CardContent,
    CardHeader,
    CircularProgress, Collapse, Fab, Grow, IconButton, InputAdornment, lighten,
    List,
    ListItem,
    ListItemText, MenuItem,
    Select,
    Skeleton, TextField, Typography
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import _ from "lodash";
import {useSelector, useDispatch} from "react-redux";
import {
    MARKETING_CLEAR_INSTANCE, MARKETING_CLONE_INSTANCE,
    MARKETING_LOAD_PRODUCT, MARKETING_SAVE_INSTANCE,
    MARKETING_UPDATE_INSTANCE_FIELD,
    MARKETING_UPDATE_PRICE
} from "../actions/marketing";
import NumberFormat from "react-number-format";
import clsx from "clsx";
import {Authorization} from "@cosmos/runes";

const sxEditUnit = theme => ({
    br: '0.25em',
    mb: '0.25em',
    p: '0.25em',
    alignItems: 'flex-end',
    '&.ready': {
        backgroundColor: lighten(theme.palette.primary.light, 0.7)
    }
});
const sxEditLabel = {width: '4em', flexGrow: 0};
const sxEditField = {display: 'flex', flexGrow: 1, ml: '1em'};
const sxPriceUnit = theme => ({
    mb: '0.25em',
    '&.ready': {
        backgroundColor: lighten(theme.palette.primary.light, 0.7)
    }
});
const updateField = (field, value) => ({type: MARKETING_UPDATE_INSTANCE_FIELD, path: field, payload: value});
const updatePrice = (component, value) => ({type: MARKETING_UPDATE_PRICE, path: component, payload: value});
export function PriceTagEditor({instancePath}) {
    const dispatch = useDispatch();
    const instance = useSelector(state => _.get(state, instancePath))
    const currencies = useSelector(state => state.marketing.currencies);
    const traders = useSelector(state => state.marketing.traders);
    const brands = useSelector(state => state.marketing.brands);
    const products = useSelector(state => state.marketing.products);
    const fieldLoading = useSelector(state => state.marketing.fieldLoading);
    const [expend, setExpend] = useState(false);
    const [symbol, setSymbol] = useState('');
    const [alert, setAlert] = useState(null);
    useEffect(() => {
        setSymbol(currencies[instance.currencyId].symbol);
    }, [currencies, setSymbol, instance])

    const saveAction = (e) => {
        if (!instance.trader) {
            setAlert('Please select a supplier!!');
            return;
        }
        if (!instance.brand) {
            setAlert('Please select a brand');
            return;
        }
        if (!instance.product) {
            setAlert('Please select a product');
            return;
        }
        if (!instance.currencyId) {
            setAlert('Please select a currency');
            return;
        }
        if (!instance.amount) {
            setAlert('Please set Amount');
            return;
        }
        if (!instance.quantity) {
            setAlert('Please set quantity');
            return;
        }
        dispatch({type: MARKETING_SAVE_INSTANCE})
    }

    return <Card sx={{flexGrow: 1}}>
        <CardHeader title={instance.id ? `Supply: #${instance.id}` : 'New Offer'}/>
        <Skeleton variant="rectangular" height={200}/>
        <CardActions sx={{justifyContent: 'flex-end', position: 'relative'}}>
            <Fab color="warning" variant="extended" size="small"
                 onClick={() => dispatch({type: MARKETING_CLEAR_INSTANCE})}>Clear</Fab>
            <Box sx={{flexGrow: 1}}/>
            <Fab color="primary" variant="extended" size="small" sx={{color: 'common.white'}}
                 onClick={() => dispatch({type: MARKETING_CLONE_INSTANCE})}>Clone</Fab>
            <Fab color="info" variant="extended" size="small"
                 onClick={saveAction}>
                {!instance.id ? 'Add' : 'Save'}</Fab>
            <Box sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                border: '1px solid #fcaaaa',
                borderRadius: '4px',
                transform: 'translateY(100%)',
                display: (!!alert) ? 'flex': 'none',
                alignItems: 'center',
                padding: '0.25rem',
                color: 'error.main',
                fontWeight: 'bold',
                width: '100%',
                height: '2rem',
                zIndex: 1000
            }}>
                <IconButton onClick={()=>{setAlert(null)}}>
                    <CancelIcon/>
                </IconButton>
                {alert}
            </Box>
        </CardActions>
        <CardContent>
            <List sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: '24rem',
                '& ul': {padding: 0}
            }}
            >
                <ListItem sx={sxEditUnit} className={clsx({'ready': instance.trader})}>
                    <ListItemText sx={sxEditLabel}>Supplier</ListItemText>
                    <Box sx={sxEditField}>
                        <Autocomplete
                            // freeSolo
                            fullWidth
                            options={traders}
                            getOptionLabel={(option) => option.name}
                            value={instance.trader}
                            isOptionEqualToValue={(opt, val) => opt.id === val.id}
                            onChange={(event, value) => {
                                dispatch(updateField('trader', value))
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin='dense'
                                    variant="standard"
                                />
                            )}
                        />
                    </Box>
                </ListItem>
                <ListItem sx={sxEditUnit} className={clsx({'ready': instance.brand})}>
                    <ListItemText sx={sxEditLabel}>Brand</ListItemText>
                    <Box sx={sxEditField}>
                        <Autocomplete
                            // freeSolo
                            fullWidth
                            getOptionLabel={(option) => option.name}
                            options={brands}
                            value={instance.brand}
                            isOptionEqualToValue={(opt, val) => opt.id === val.id}
                            onChange={(event, value) => {
                                dispatch(updateField('brand', value))
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin='dense'
                                    variant="standard"
                                />
                            )}
                        />
                    </Box>
                </ListItem>
                <ListItem sx={sxEditUnit} className={clsx({'ready': instance.product})}>
                    <ListItemText sx={sxEditLabel}>Product</ListItemText>
                    <Box sx={sxEditField}>
                        <Autocomplete
                            // freeSolo
                            fullWidth
                            options={products}
                            getOptionLabel={option => `${option.SKU} - ${option.dialColor}`}
                            value={instance.product}
                            onChange={(event, value) => {
                                dispatch(updateField('product', value))
                            }}
                            onInputChange={(event, value, reason) => {
                                if (reason !== 'reset') {
                                    dispatch({type: MARKETING_LOAD_PRODUCT, payload: event.target.value});
                                }
                            }}
                            isOptionEqualToValue={(o, v) => o.id === v.id}
                            renderOption={(props, option, state) => (
                                <Box component={'li'} key={`p-${option.id}`} sx={{
                                    padding: '6px 16px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start !important',
                                    justifyContent: 'center !important',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }} {...props} >
                                    <Typography
                                        sx={{flexGlow: 1}}>{option.SKU} {option.dialColor && `- ${option.dialColor}`}</Typography>
                                    <Typography sx={{
                                        flexGlow: 1,
                                        fontSize: '75%',
                                        color: '#606060'
                                    }}>{option.reference}</Typography>
                                </Box>)}
                            loading={fieldLoading}
                            loadingText={"Loading Product..."}
                            noOptionsText={"Search by Reference"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin='dense'
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (<>
                                            {fieldLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </>)
                                    }}
                                />
                            )}
                        />
                    </Box>
                </ListItem>
                <ListItem sx={sxEditUnit} className={clsx({'ready': instance.currencyId})}>
                    <ListItemText sx={sxEditLabel}>
                        <Typography>
                            Currency
                        </Typography>
                    </ListItemText>
                    <Box sx={sxEditField}>
                        <Select
                            variant="standard"
                            fullWidth
                            value={instance.currencyId}
                            onChange={e => {
                                dispatch(updateField('currencyId', e.target.value))
                            }}
                        >
                            {
                                Object.values(currencies).map(cur => (
                                    <MenuItem key={`cur-${cur.id}`}
                                              value={cur.id}><b>{cur.symbol}</b> - <em>{cur.name}</em> </MenuItem>
                                ))
                            }
                        </Select>
                    </Box>
                </ListItem>
                <Box sx={sxPriceUnit} className={clsx({'ready': instance.amount})}>
                    <ListItem sx={sxEditUnit}>
                        <ListItemText sx={sxEditLabel}>
                            <Typography sx={{fontWeight: !expend ? '400' : '700'}}>
                                {!expend ? 'Amount' : 'Pricing'}
                            </Typography>
                            <Typography sx={{fontSize: '75%'}}>
                                {expend ? '(with Tax)' : ''}
                            </Typography>
                        </ListItemText>
                        <Box sx={sxEditField}>
                            <Grow in={!expend}>
                                <Box>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        variant="standard"
                                        value={instance.amount}
                                        onChange={event => dispatch(updatePrice('amount', parseFloat(event.target.value)))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
                                        }}
                                    />
                                </Box>
                            </Grow>
                        </Box>
                        <IconButton sx={{position: 'absolute', right: 0}} onClick={() => {
                            setExpend(!expend)
                        }}>
                            {expend ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                    </ListItem>
                    <Collapse in={expend} timeout='auto'>
                        <List component="div" disablePadding>
                            <ListItem sx={sxEditUnit}>
                                <ListItemText sx={sxEditLabel}>
                                    <Typography>
                                        MSRP
                                    </Typography>
                                    <Typography sx={{fontSize: '75%'}}>
                                        (with Tax)
                                    </Typography>
                                </ListItemText>
                                <Box sx={sxEditField}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        variant="standard"
                                        value={instance.msrp}
                                        onChange={event => dispatch(updatePrice('msrp', parseFloat(event.target.value)))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
                                        }}
                                    />
                                </Box>
                            </ListItem>
                            <ListItem sx={sxEditUnit}>
                                <ListItemText sx={sxEditLabel}>
                                    <Typography>
                                        Discount
                                    </Typography>
                                </ListItemText>
                                <Box sx={sxEditField}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        variant="standard"
                                        value={instance.discount}
                                        onChange={event => dispatch(updatePrice('discount', parseFloat(event.target.value)))}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                                        }}
                                    />
                                </Box>
                            </ListItem>
                            <ListItem sx={sxEditUnit}>
                                <ListItemText sx={sxEditLabel}>
                                    <Typography>
                                        Price
                                    </Typography>
                                    <Typography sx={{fontSize: '75%'}}>
                                        (w/o Tax)
                                    </Typography>
                                </ListItemText>
                                <Box sx={sxEditField}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        variant="standard"
                                        value={instance.price}
                                        onChange={event => dispatch(updatePrice('price', parseFloat(event.target.value)))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
                                        }}
                                    />
                                </Box>
                            </ListItem>
                            <ListItem sx={sxEditUnit}>
                                <ListItemText sx={sxEditLabel}>
                                    <Typography>
                                        Tax
                                    </Typography>
                                </ListItemText>
                                <Box sx={sxEditField}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        variant="standard"
                                        value={instance.tax}
                                        disabled={true}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
                                        }}
                                    />
                                </Box>
                            </ListItem>
                            <ListItem sx={sxEditUnit}>
                                <ListItemText sx={sxEditLabel}>
                                    <Typography>
                                        Amount
                                    </Typography>
                                    <Typography sx={{fontSize: '75%'}}>
                                        (with Tax)
                                    </Typography>
                                </ListItemText>
                                <Box sx={sxEditField}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        variant="standard"
                                        value={instance.amount}
                                        onChange={event => dispatch(updatePrice('amount', parseFloat(event.target.value)))}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
                                        }}
                                    />
                                </Box>
                            </ListItem>
                        </List>
                    </Collapse>
                </Box>
                <ListItem sx={sxEditUnit} className={clsx({'ready': instance.quantity})}>
                    <ListItemText sx={sxEditLabel}>Quantity</ListItemText>
                    <Box sx={sxEditField}>
                        <TextField
                            value={instance.quantity}
                            onChange={e => {
                                dispatch(updateField('quantity', parseInt(e.target.value)))
                            }}
                            fullWidth
                            type='number'
                            margin='dense'
                            variant="standard"
                        />
                    </Box>
                </ListItem>
                <Authorization allowed={["cosmos.operation.merchant"]}>
                    <ListItem sx={sxEditUnit} className={clsx({'ready': instance.validFor})}>
                        <ListItemText sx={sxEditLabel}>Valid For</ListItemText>
                        <Box sx={sxEditField}>
                            <TextField
                                value={instance.validFor}
                                onChange={e => {
                                    dispatch(updateField('validFor', parseInt(e.target.value)))
                                }}
                                fullWidth
                                type='number'
                                margin='dense'
                                variant="standard"
                                InputProps={{endAdornment: <InputAdornment position={'end'}>Days</InputAdornment>}}
                            />
                        </Box>
                    </ListItem>
                </Authorization>
            </List>
        </CardContent>
    </Card>
}