import React from 'react';
import {Box} from "@mui/material";

export function StatusBar(){
    return <Box sx={{
        display: {xs: 'none', md: 'flex'},
        position: 'sticky',
        bottom: 0,
        height: '2em',
        width: '100vw',
        backgroundColor: '#FAFAFA'
    }}>

    </Box>
}