import {
    Box, Button,
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Fab,
    LinearProgress,
    Tab,
    Tabs,
    Toolbar,
    Typography
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AutoSizer from 'react-virtualized-auto-sizer';
import NumberFormat from "react-number-format";
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import UpdateTwoToneIcon from '@mui/icons-material/UpdateTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import {isEmpty} from "lodash";
import {DataTable, isAllSelected} from '@cosmos/runes';
import {
    BATCH_SUPPLY_OP_CANCEL, BATCH_SUPPLY_OP_EXECUTE,
    BATCH_SUPPLY_OPERATION,
    DESELECT_SUPPLY_ALL_ROWS,
    LOAD_SUPPLIES, MARKETING_SELECT_INSTANCE, MARKETING_TYPE_AND_SEARCH, SELECT_SUPPLY_ALL_ROWS,
    SELECT_SUPPLY_OFFER,
    TOGGLE_EXTENDED_MODE
} from "../../actions/marketing";
import moment from 'moment';
import {PriceTagEditor, SearchBox} from "../../components";
import {green} from "@mui/material/colors";
import {LoadingButton} from "@mui/lab";

function CheckBoxHeader({sx, data}) {
    const dispatch = useDispatch();
    const selected = useSelector((state) => state.marketing.selected);
    // const index = useSelector(state => state.marketing.supplyIdx);
    const [index, setIndex] = useState([]);
    useEffect(() => {
        setIndex(data.map(d => d.id));
    }, [setIndex, data])

    const allSelected = isAllSelected(index, selected);
    const handleChange = (e) => {
        if (allSelected) {
            dispatch({type: DESELECT_SUPPLY_ALL_ROWS, payload: index})
        } else {
            dispatch({type: SELECT_SUPPLY_ALL_ROWS, payload: index})
        }
    };
    return <Box sx={sx}>
        <Checkbox color="primary" checked={selected.length > 0}
                  onChange={handleChange}
                  indeterminate={selected.length > 0 && !allSelected}
        />
    </Box>
}

function OperationDialog({title, message, confirm, cancel}) {
    const dispatch = useDispatch()
    const dialog = useSelector(state => state.marketing.dialog);
    const processing = useSelector(state => state.marketing.processing);
    const error = useSelector(state => state.marketing.error);
    const success = useSelector(state => state.marketing.success);
    const cancelBatchOp = () => {
        dispatch({type: cancel})
    }
    const executeBatchOp = () => {
        dispatch({type: confirm})
    }
    return <Dialog
        open={dialog}
        onClose={cancelBatchOp}
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <Box sx={{height: '4px'}}>
            {processing && <LinearProgress variant={"indeterminate"}/>}
        </Box>
        <DialogContent>
            {error &&
                <Box sx={{mb: 4, color: 'error.main', fontSize: '120%', display: 'flex', alignItems: 'center'}}>
                    <ErrorIcon fontSize={'large'}/>
                    <DialogContentText sx={{ml: 2, color: 'error.main'}}>
                        Operation Failed
                    </DialogContentText>
                </Box>
            }
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {!success && <Button
                color="error"
                onClick={cancelBatchOp}
                disabled={success}
            >

                <CancelIcon/>Cancel
            </Button>}
            {success ?
                <Fab color="success"
                     onClick={cancelBatchOp}
                >
                    <CheckIcon/>
                </Fab>
                :
                <LoadingButton
                    loading={processing}
                    loadingPosition="start"
                    color={error ? 'error' : 'info'}
                    onClick={executeBatchOp}
                    startIcon={<SaveIcon/>}
                    disabled={error}
                >
                    Confirm
                </LoadingButton>
            }
        </DialogActions>
    </Dialog>
}


export function Supplies() {
    const dispatch = useDispatch();
    // View States
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [filtered, setFiltered] = useState(0);
    // Redux State
    const loading = useSelector(state => state.marketing.loading);
    const search = useSelector(state => state.marketing.search);
    // Basic Data
    const currencies = useSelector(state => state.marketing.currencies);
    const indexes = useSelector(state => state.marketing.supplyIdx);
    const supplies = useSelector(state => state.marketing.supplies);
    const selected = useSelector(state => state.marketing.selected);

    const extendedMode = useSelector(state => state.marketing.extendedMode);
    // Filter Control
    const section = useSelector(state => state.marketing.section);
    const sections = useSelector(state => state.marketing.sections);

    const operation = useSelector(state => state.marketing.operation);

    const filterFeedback = useCallback((data) => {
        setFiltered(data.length);
    }, [setFiltered])

    // Setup data
    useEffect(() => {
        if (!isEmpty(supplies) && indexes.length > 0) {
            const temp = indexes.map(idx => supplies[idx]);
            setData(temp);
        } else {
            setData([]);
        }
    }, [indexes, supplies, setData]);

    useEffect(() => {
        dispatch({type: LOAD_SUPPLIES});
    }, [section, dispatch])

    useEffect(() => {
        const columns = [
            {
                name: 'control',
                title: '',
                width: 64,
                header: ({sx, data}) => {
                    return <CheckBoxHeader sx={sx} data={data}/>
                },
                cell: ({rowData}) => (<Box>
                    <Checkbox
                        sx={{pt: 0, pb: 0}}
                        disableRipple
                        color="primary"
                        checked={selected.indexOf(rowData.id) > -1}
                        onChange={() => {
                            dispatch({type: SELECT_SUPPLY_OFFER, payload: rowData.id});
                        }}
                    />
                    <Box sx={{display: 'none'}}>{rowData.id}</Box>
                </Box>)
            },
            {
                name: 'product.brand',
                title: 'Brand',
                width: 96,
                sortBy: "product.brand",
                filter: 'product.brand'
            },
            {
                name: 'product.reference',
                title: 'Product',
                width: 160,
                sortBy: "product.reference",
                sx: theme=>({paddingTop: theme.spacing(0.5), paddingBottom: theme.spacing(0.5), display: 'flex', alignItems: 'center'}),
                cell: ({rowData})=><Box sx={{}}>
                    <Typography>{rowData.product?.SKU}</Typography>
                    <Typography sx={{fontSize: '75%', color: '#606060'}}>{rowData.product?.reference}</Typography>
                </Box>
            },
            {
                name: 'product.dialColor',
                title: 'Color',
                width: 72,
                sortBy: "product.dialColor",
            },
            {
                name: 'supplier',
                title: 'Supplier',
                width: 160,
                sortBy: 'supplier',
                filter: 'supplier'
            },
            {
                name: 'msrp',
                title: 'MSRP',
                numeric: true,
                cell: ({rowData}) => rowData['msrp'] > 0 && (
                    <NumberFormat
                        value={rowData["msrp"]}
                        prefix={currencies[rowData.currencyId].symbol || ''}
                        displayType='text'
                        thousandSeparator
                    />),
                sortBy: 'msrp'
            },
            {
                name: 'discount',
                title: 'Dis',
                numeric: true,
                width: 50,
                cell: ({rowData}) => rowData['discount'] > 0 && (
                    <NumberFormat
                        value={rowData["discount"]}
                        prefix={currencies[rowData.currencyId].symbol || ''}
                        displayType='text'
                        thousandSeparator
                    />),
                sortBy: 'discount'
            },
            {
                name: 'price',
                title: 'Price',
                numeric: true,
                cell: ({rowData}) => rowData['price'] > 0 && (
                    <NumberFormat
                        value={rowData["price"]}
                        prefix={currencies[rowData.currencyId].symbol || ''}
                        displayType='text'
                        thousandSeparator
                    />),
                sortBy: 'price'
            },
            {
                name: 'tax',
                title: "Tax",
                numeric: true,
                cell: ({rowData}) => rowData['tax'] !== 0 && (
                    <NumberFormat
                        value={rowData["tax"]}
                        prefix={currencies[rowData.currencyId].symbol || ''}
                        displayType='text'
                        thousandSeparator
                    />),
                sortBy: 'tax'
            },
            {
                name: 'amount',
                title: 'Amount',
                numeric: true,
                cell: ({rowData}) => rowData['amount'] > 0 && (
                    <NumberFormat
                        value={rowData["amount"]}
                        prefix={currencies[rowData.currencyId].symbol || ''}
                        displayType='text'
                        thousandSeparator
                    />),
                sortBy: 'amount'
            }
        ];
        if (section === 20401) {
            columns.push({
                name: 'validTo',
                title: 'Expire',
                width: 96,
                cell: ({sx, rowData}) => {
                    const expire = moment(rowData["validTo"]).fromNow();
                    return <Box sx={sx}>{expire}</Box>;
                },
                sortBy: 'validTo'
            })
        }
        columns.push({
            name: 'lastSeenAt',
            title: 'Ver.At',
            width: 96,
            cell: ({sx, rowData}) => {
                return <Box sx={sx}>
                    {moment(rowData['lastSeenAt']).format("YYYY-MM-DD")}
                </Box>
            },
            sortBy: 'lastSeenAt'
        })
        setColumns(columns);
    }, [setColumns, section, selected])

    return <>
        <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
            <Toolbar
                sx={{bgcolor: 'primary.light'}}
                variant="dense"
            >
                <Typography variant="h5">Supplies</Typography>
                <Typography sx={{ml: 2, bgcolor: 'primary.main', p: 1}}
                            variant="h6">{sections[section].name}</Typography>

                <Box sx={{flexGrow: 1}}>
                    <SearchBox selector={"marketing.search"} action={MARKETING_TYPE_AND_SEARCH}/>
                </Box>
                {filtered > 0 && <Typography sx={{ml: 2}}>Filtered: {filtered}</Typography>}
                <Typography sx={{ml: 2}}>Total: {data.length}</Typography>
                <Box sx={{ml: 16, display: 'flex', alignItems: 'center'}}>
                    <Tabs
                        sx={{
                            '& .MuiTabs-indicator': {backgroundColor: green[900]}
                        }}
                        value={extendedMode}
                        onChange={(e, value) => {
                            dispatch({type: TOGGLE_EXTENDED_MODE, payload: value})
                        }}>
                        <Tab sx={{'&.Mui-selected': {color: green[900]}}} label={"Current"} value={0}/>
                        <Tab sx={{'&.Mui-selected': {color: green[900]}}} label={"Review"} value={2}/>
                        <Tab sx={{'&.Mui-selected': {color: green[900]}}} label={"Expired"} value={1}/>
                    </Tabs>
                    <Fab sx={{ml: 8}}
                         variant="extended"
                         size="small"
                         color="info"
                         onClick={() => {
                             dispatch({type: LOAD_SUPPLIES})
                         }}
                    ><RefreshTwoToneIcon/>Reload</Fab>
                </Box>
            </Toolbar>
            <Box sx={{flexGrow: 0, height: '4px'}}>
                {loading && <LinearProgress variant="indeterminate" color="secondary"/>}
            </Box>
            <Toolbar variant='dense' sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                <Box>
                    {selected.length > 0 && <Typography>Selected: {selected.length} Item(s)</Typography>}
                </Box>
                <Box sx={{flexGrow: 1}}>
                </Box>
                <Box>
                    {selected.length > 0 &&
                        <>
                            <Fab
                                variant="extended"
                                size="small"
                                color="info"
                                onClick={() => {
                                    dispatch({type: BATCH_SUPPLY_OPERATION, payload: "REFRESH"})
                                }}
                            >
                                <UpdateTwoToneIcon/>
                                {extendedMode === 1 ? 'Renew' : 'Refresh'}
                            </Fab>
                            <Fab sx={{ml: 2}}
                                 variant="extended"
                                 size="small"
                                 color="warning"
                                 onClick={() => {
                                     dispatch({type: BATCH_SUPPLY_OPERATION, payload: "DELETE"})
                                 }}
                            >
                                <RemoveCircleTwoToneIcon/>
                                Delete
                            </Fab>
                        </>
                    }
                </Box>
                <Box sx={{minWidth: '8rem'}}>
                </Box>
            </Toolbar>
            <Box sx={{flexGrow: 1}}>
                <AutoSizer>
                    {({width, height}) => (
                        <DataTable
                            data={data}
                            columns={columns}
                            width={width}
                            height={height}
                            dataCallback={filterFeedback}
                            rowOnClick={({rowData, index}) =>{
                                dispatch({type: MARKETING_SELECT_INSTANCE, payload: rowData})
                            }}
                        />
                    )}
                </AutoSizer>
            </Box>
        </Box>
        <Box sx={{display: {xs: 'none', md: 'flex'}, width: '20rem'}}>
            <PriceTagEditor instancePath={'marketing.instance'}/>
        </Box>
        <OperationDialog
            title={`Operation ${operation && operation.toUpperCase()}`}
            message={`You are going to ${operation && operation.toLowerCase()} ${selected.length} record from [${sections[section].name}]`}
            confirm={BATCH_SUPPLY_OP_EXECUTE}
            cancel={BATCH_SUPPLY_OP_CANCEL}
        />
    </>
}