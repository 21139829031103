import {Card, Grid} from "@mui/material";

export function MarketingDash() {
    return <Grid container columnSpacing={2} rowSpacing={2} sx={{padding: 2}}>
        <Grid item xs={3}>
            <Card>Offers</Card>
        </Grid>
        <Grid item xs={3}>
            <Card>Supplies</Card>
        </Grid>
        <Grid item xs={3}>
            <Card>Price Rule</Card>
        </Grid>
        <Grid item xs={3}>
            <Card>Alert</Card>
        </Grid>
    </Grid>
}