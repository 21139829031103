import {Box, InputBase, alpha} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {useCallback} from "react";
export function SearchBox({sx, selector, action}) {
    const sel = useCallback(typeof selector === 'function'
        ? selector
        : state=>_.get(state, selector), [selector]);
    const dispatch = useDispatch();
    const search = useSelector(sel);
    const handleChange = (e)=>{
        const act = typeof action === 'function'
            ? action(e.target.value)
            : {type: action, payload: e.target.value}
        dispatch(act)
    }

    return <Box sx={theme => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {background: alpha(theme.palette.common.white, 0.25)},
        marginRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        },
        ...sx
    })}>
        <Box sx={theme => ({
            padding: theme.spacing(0, 2),
            height: '100%',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        })}>
            <SearchIcon/>
        </Box>
        <InputBase sx={theme => ({
            color: 'inherit',
            flexGrow: 1,
            '& .MuiInputBase-input': {
                padding: theme.spacing(1, 2, 1, 0),
                transition: theme.transitions.create('width'),
                width: '100%',
            }
        })}
                   placeholder="Search by reference..."
                   value={search}
                   onChange={handleChange}
        />
    </Box>
}