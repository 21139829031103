import {Catalog, Marketing, MarketingDash, Supplies} from "./pages";
import {Box} from "@mui/material";

export const features = [
    {
        name: 'marketing',
        path: '/marketing',
        permission: [],
        component: Marketing,
        dash: MarketingDash,
        children: [
            {
                name: 'offers',
                path: '/offers',
                permission: [],
                component: ()=><Box>Offers</Box>
            },
            {
                name: 'supplies',
                path: '/supplies',
                permission: [],
                component: Supplies
            },
            {
                name: 'pricing',
                path: '/pricing',
                permission: [],
                component: ()=><Box>Pricing</Box>
            }
        ],
    },
    {
        name: 'catalog',
        path: '/catalog',
        permission: [],
        component: ()=><Catalog/>,
        children: [
            {
                name: 'products',
                path: '/products',
                permission: [],
                component: ()=><Box>Products</Box>
            },
            {
                name: 'gtin',
                path: '/gtin',
                permission: [],
                component: ()=><Box>GTIN</Box>
            }
        ]
    }
]


