import {configureStore} from "@reduxjs/toolkit";
import {createVibesMiddleware} from "@cosmos/vibes";
import {vibes} from './vibes';
import {marketingReducer, systemReducer} from "./features";
import {authReducer} from '@cosmos/runes';
import {connectAxios, getAxiosClient} from "./utils";
import {SearchService} from "./services";

const axios = getAxiosClient();
const middleware = [
    createVibesMiddleware(vibes, {
        axios,
        search: new SearchService()
    })
];

export const store = configureStore({
    reducer: {
        system: systemReducer,
        auth: authReducer,
        marketing: marketingReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware)
})

connectAxios(axios, store);
