import {
    markingVibes,
    systemInitVibes,
} from "./features";
import {authLogin, authRefresh, authLogout} from '@cosmos/runes';

export const vibes = [
    systemInitVibes,
    // Authentication
    authRefresh,
    authLogout,
    authLogin(),

    // Marketing
    ...markingVibes
]