export const LOAD_SUPPLIES = '@@marketing/LOAD_SUPPLIES';
export const LOAD_SUPPLIES_SUCCESS = "@@marketing/LOAD_SUPPLIES_SUCCESS";
export const LOAD_SUPPLIES_FAIL = "@@marketing/LOAD_SUPPLIES_FAIL";

export const TOGGLE_EXTENDED_MODE = '@@marketing/TOGGLE_EXTENDED_MODE';
export const SELECT_SUPPLY_OFFER = '@@marketing/SELECT_SUPPLY_OFFER';

export const DESELECT_SUPPLY_ALL_ROWS = '@@marketing/DESELECT_SUPPLY_ALL';
export const SELECT_SUPPLY_ALL_ROWS = '@@marketing/SELECT_SUPPLY_ALL';
export const UPDATE_SUPPLIES_SELECTED = '@@marketing/UPDATE_SUPPLIES_SELECTED';

export const BATCH_SUPPLY_OPERATION = '@@marketing/BATCH_SUPPLY_OPERATION';
export const BATCH_SUPPLY_OP_CANCEL = '@@marketing/BATCH_SUPPLY_OP_CANCEL';
export const BATCH_SUPPLY_OP_EXECUTE = '@@marketing/BATCH_SUPPLY_OP_EXECUTE';
export const BATCH_SUPPLY_OP_SUCCESS = '@@marketing/BATCH_SUPPLY_OP_SUCCESS';
export const BATCH_SUPPLY_OP_FAILED = '@@marketing/BATCH_SUPPLY_OP_FAILED';

export const MARKETING_TYPE_AND_SEARCH = '@@marketing/TYPE_AND_SEARCH';
export const MARKETING_FILTER_APPLIED = '@@marketing/FILTER_APPLIED';
export const MARKETING_UPDATE_INSTANCE_FIELD = '@@marketing/UPDATE_INSTANCE_FIELD';
export const MARKETING_UPDATE_PRICE = '@@marketing/UPDATE_PRICE';
export const MARKETING_UPDATE_PRICING = '@@marketing/UPDATE_PRICING';
export const MARKETING_LOAD_PRODUCT = '@@marketing/LOAD_PRODUCT';
export const MARKETING_LOAD_PRODUCT_SUCCESS = '@@marketing/LOAD_PRODUCT_SUCCESS';
export const MARKETING_SELECT_INSTANCE = '@@marketing/SELECT_INSTANCE';
export const MARKETING_SAVE_INSTANCE = "@@marketing/SAVE_INSTANCE";
export const MARKETING_CLONE_INSTANCE = "@@marketing/CLONE_INSTANCE";
export const MARKETING_CLEAR_INSTANCE = "@@marketing/CLEAR_INSTANCE";
export const MARKETING_SAVE_SUPPLY_SUCCESS = "@@marketing/SAVE_SUPPLY_SUCCESS";
export const MARKETING_SAVE_SUPPLY_FAILED = '@@marketing/SAVE_SUPPLY_FAILED'
