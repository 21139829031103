import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Navigation, Splash, StatusBar, SideMenu} from "./components";
import {APP_STATE_INIT} from "./actions/system";
import {Home} from "./pages";
import {Box} from "@mui/material";
import {features} from './system';
import {LoginWidget} from '@cosmos/runes';

export function App() {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: APP_STATE_INIT});
    }, [dispatch])
    return (
        <>
            {(!isAuthenticated)
                ? <LoginWidget/>
                : <>
                    <Navigation/>
                    <Box sx={{flexGrow: 1, display: 'flex'}}>
                        <SideMenu/>
                        <Routes>
                            <Route index element={<Home/>}/>
                            <Route path="/" element={<Home/>}/>
                            {features.map(feature => (
                                <Route
                                    key={`da-${feature.name}`}
                                    path={feature.path}
                                    element={<feature.component/>}>
                                    {feature.children && feature.children
                                        .map(child => (
                                            <Route
                                                key={`da-sub-${child.name}`}
                                                path={`${feature.path}${child.path}`}
                                                element={<child.component/>}/>
                                        ))}
                                    {feature.dash && <Route
                                        key={`da-major`}
                                        path=""
                                        element={<feature.dash/>}/>}
                                </Route>
                            ))}
                            <Route path={"/admin"} element={<Box>Admin</Box>}/>
                            <Route path={"/login"} element={<LoginWidget/>}/>
                        </Routes>
                    </Box>
                    <StatusBar/>
                </>
            }
            <Splash/>
        </>
    )
}