import React from 'react';
import {NavLink} from "react-router-dom";
import clsx from "clsx";

export const LinkBehavior = React.forwardRef((props, ref)=>{
    const {href, className, ...other} = props;
    return <NavLink ref={ref} to={href} className={({isActive}) => clsx(className, {active:isActive})} {...other}/>
});

export function waitFor(mil) {
    return new Promise((resolve)=>{
        setTimeout(resolve, mil);
    })
}
