import React from 'react';
import * as PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(()=>({
    '@global': {
        'main#root': {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }
    }
}), {name: 'mg-layout'});

export const MGLayout = (props) =>{
    const {children = null} = props;
    useStyles();
    return <>{children}</>
};

MGLayout.propTypes = {
    children: PropTypes.node
}