import {createReducer} from "@reduxjs/toolkit";
import {createVibes} from "@cosmos/vibes";
import {
    BATCH_SUPPLY_OP_CANCEL,
    BATCH_SUPPLY_OP_EXECUTE,
    BATCH_SUPPLY_OP_FAILED,
    BATCH_SUPPLY_OP_SUCCESS,
    BATCH_SUPPLY_OPERATION,
    DESELECT_SUPPLY_ALL_ROWS,
    LOAD_SUPPLIES,
    LOAD_SUPPLIES_FAIL,
    LOAD_SUPPLIES_SUCCESS,
    MARKETING_FILTER_APPLIED,
    MARKETING_TYPE_AND_SEARCH,
    SELECT_SUPPLY_ALL_ROWS,
    SELECT_SUPPLY_OFFER,
    TOGGLE_EXTENDED_MODE,
    MARKETING_UPDATE_INSTANCE_FIELD,
    UPDATE_SUPPLIES_SELECTED,
    MARKETING_LOAD_PRODUCT,
    MARKETING_LOAD_PRODUCT_SUCCESS,
    MARKETING_UPDATE_PRICE,
    MARKETING_UPDATE_PRICING,
    MARKETING_CLEAR_INSTANCE,
    MARKETING_CLONE_INSTANCE,
    MARKETING_SAVE_INSTANCE,
    MARKETING_SELECT_INSTANCE,
    MARKETING_SAVE_SUPPLY_SUCCESS,
    MARKETING_SAVE_SUPPLY_FAILED
} from "../actions/marketing";
import _, {keyBy} from "lodash";
import {waitFor} from "../support";
import {round} from "../utils";

export const marketingReducer = createReducer(
    {
        loading: false,
        sections: {
            20401: {
                name: "3P Temp"
            },
            20201: {
                name: '3P Coming'
            },
            1001: {
                name: '3P Trust'
            },
            501: {
                name: '3P Market'
            }
        },
        section: 20401,
        extendedMode: 0,
        dialog: false,
        operation: null,
        processing: false,
        error: null,
        success: false,
        search: '',
        instance: {
            id: null,
            // brandId: -1,
            brand: null,
            // productId: -1,
            product: null,
            // traderId: 5000, must format traderId before send to server
            trader: null,
            currencyId: 1,
            price: 0,
            taxRate: 0,
            tax: 0,
            amount: 0,
            msrp: 0,
            discount: 0,
            quantity: 1,
            validFor: 14,
        },
        currencies: {
            1: {
                "id": 1,
                "symbol": "HK$"
            },
            2: {
                "id": 2,
                "symbol": "USD"
            },
            3: {
                "id": 3,
                "symbol": "CHF"
            },
            4: {
                "id": 4,
                "symbol": "€"
            },
            5: {
                "id": 5,
                "symbol": "RM"
            },
            6: {
                "id": 6,
                "symbol": "DKK"
            },
            7: {
                "id": 7,
                "symbol": "¥"
            },
            8: {
                "id": 8,
                "symbol": "C$"
            },
            9: {
                "id": 9,
                "symbol": "NZ$"
            },
            10: {
                "id": 10,
                "symbol": "NT$"
            },
            11: {
                "id": 11,
                "symbol": "S$"
            },
            12: {
                "id": 12,
                "symbol": "AED"
            },
            13: {
                "id": 13,
                "symbol": "MOP$"
            },
            14: {
                "id": 14,
                "symbol": "VND"
            },
            15: {
                "id": 15,
                "symbol": "BHD"
            },
            16: {
                "id": 16,
                "symbol": "QAR"
            },
            20: {
                "id": 20,
                "symbol": "CN¥"
            },
            22: {
                "id": 22,
                "symbol": "SEK"
            },
            24: {
                "id": 24,
                "symbol": "£"
            },
            26: {
                "id": 26,
                "symbol": "BND$"
            },
            28: {
                "id": 28,
                "symbol": "zl"
            },
            30: {
                "id": 30,
                "symbol": "Kč"
            },
            32: {
                "id": 32,
                "symbol": "NOK"
            },
            33: {
                "id": 33,
                "symbol": "₩"
            },
            35: {
                "id": 35,
                "symbol": "₱"
            },
            37: {
                "id": 37,
                "symbol": "Rp"
            }
        },
        fieldLoading: false,
        traders: [{id: 5000, name: "MARKET"}, {id: 5001, name: "HK MARKET", isoCode: 'HKG'}],
        brands: [],
        products: [],
        selected: [],
        offers: {},
        offerIdx: [],
        supplies: {},
        supplyIdx: [],
        priceRules: {},
        ruleIdx: []
    },
    builder => {
        builder
            .addCase(LOAD_SUPPLIES, (state) => {
                state.loading = true;
            })
            .addCase(LOAD_SUPPLIES_SUCCESS, (state, action) => {
                state.loading = false;
                const {supplies, currencies, brands, traders} = action.payload;
                state.supplies = supplies;
                // state.supplyIdx = index;
                state.currencies = currencies;
                state.brands = brands;
                state.traders = traders;
                // state.selected = [];
                // state.instance = {};
            })
            .addCase(TOGGLE_EXTENDED_MODE, (state, action) => {
                state.extendedMode = action.payload;
                state.loading = true;
            })
            .addCase(UPDATE_SUPPLIES_SELECTED, (state, action) => {
                state.selected = action.payload.selected;
            })
            .addCase(BATCH_SUPPLY_OPERATION, (state, action) => {
                state.dialog = true;
                state.operation = action.payload;
            })
            .addCase(BATCH_SUPPLY_OP_CANCEL, (state) => {
                state.dialog = false;
                state.operation = null;
                state.processing = false;
                state.error = null;
                state.success = false;
            })
            .addCase(BATCH_SUPPLY_OP_EXECUTE, (state) => {
                state.processing = true;
            })
            .addCase(BATCH_SUPPLY_OP_SUCCESS, (state) => {
                state.processing = false;
                state.success = true;
            })
            .addCase(BATCH_SUPPLY_OP_FAILED, (state, action) => {
                state.processing = false;
                state.error = action;
            })
            .addCase(MARKETING_TYPE_AND_SEARCH, (state, action) => {
                state.search = action.payload;
            })
            .addCase(MARKETING_FILTER_APPLIED, (state, action) => {
                state.supplyIdx = action.payload;
            })
            .addCase(MARKETING_UPDATE_INSTANCE_FIELD, (state, action) => {
                _.set(state.instance, action.path, action.payload);
                if (action.path === 'trader') {
                    console.log(action.payload);
                    switch (action.payload.isoCode) {
                        case 'JPN':
                            state.instance.taxRate = 10;
                            state.instance.currencyId = 7;
                            break;
                        case 'SGP':
                            state.instance.taxRate = 7
                            state.instance.currencyId = 11;
                            break;
                        default:
                            state.instance.taxRate = 0
                    }
                }
                if (action.path === 'brand') {
                    state.products = [];
                }
            })
            .addCase(MARKETING_LOAD_PRODUCT, state => {
                state.fieldLoading = true;
            })
            .addCase(MARKETING_LOAD_PRODUCT_SUCCESS, (state, action) => {
                state.fieldLoading = false;
                state.products = action.payload
            })
            .addCase(MARKETING_UPDATE_PRICE, (state, action) => {

                _.set(state.instance, action.path, (Number.isNaN(action.payload) ? 0 : action.payload));
            })
            .addCase(MARKETING_UPDATE_PRICING, (state, action) => {
                state.instance.msrp = action.payload.msrp;
                state.instance.discount = action.payload.discount;
                state.instance.price = action.payload.price;
                state.instance.tax = action.payload.tax;
                state.instance.amount = action.payload.amount;
            })
            .addCase(MARKETING_SELECT_INSTANCE, (state, action) => {
                const {payload} = action;
                state.instance = {
                    id: payload.id,
                    brand: {id: payload.brandId, name: payload.product.brand},
                    product: {
                        id: payload.productId,
                        reference: payload.product.reference,
                        SKU: payload.product.SKU,
                        dialColor: payload.product.dialColor
                    },
                    trader: {id: payload.traderId, name: payload.supplier},
                    currencyId: payload.currencyId,
                    taxRate: Math.round((payload.tax / payload.price) * 100),
                    price: payload.price,
                    tax: payload.tax,
                    amount: payload.amount,
                    msrp: payload.msrp,
                    discount: payload.discount,
                    quantity: 1
                }
            })
            .addCase(MARKETING_CLEAR_INSTANCE, (state) => {
                state.instance = {
                    id: null,
                    brand: null,
                    product: null,
                    trader: null,
                    currencyId: 1,
                    price: 0,
                    taxRate: 0,
                    tax: 0,
                    amount: 0,
                    msrp: 0,
                    discount: 0,
                    quantity: 1,
                    validFor: 14
                }
            })
            .addCase(MARKETING_CLONE_INSTANCE, (state) => {
                state.instance.id = null;
            })
            .addCase(MARKETING_SAVE_INSTANCE, (state) => {
                state.processing = true;
            })
            .addCase(MARKETING_SAVE_SUPPLY_SUCCESS, (state, action) => {
                state.processing = false;
                if (action.payload.prev) {
                    delete state.supplies[action.payload.prev];
                    const idx = state.supplyIdx.indexOf(action.payload.prev);
                    state.supplyIdx.splice(idx, 1);
                }
                state.supplies[action.payload.instance.id] = action.payload.instance;
                state.supplyIdx.unshift(action.payload.instance.id);
                state.instance.id = null;
                state.product = null;
            })
            .addCase(MARKETING_SAVE_SUPPLY_FAILED, (state, action) => {
                state.processing = false;
                console.log(action);
            });
    });

export const loadSuppliesVibes = createVibes({
    type: [LOAD_SUPPLIES, TOGGLE_EXTENDED_MODE],
    latest: true,
    processOptions: {
        successType: LOAD_SUPPLIES_SUCCESS,
        failType: LOAD_SUPPLIES_FAIL,
    },
    async process({getState, search, axios}) {
        const state = getState().marketing;
        const {section, extendedMode} = state;

        const variables = {
            filter: {
                channelId: section,
                extend: extendedMode
            }
        };
        const {data} = await axios.request({
            url: '/aprs',
            method: 'post',
            data: {
                query: `query loadSupplies($filter: SuppliesFilter) {
            currencies {id symbol name}
            traders {id name isoCode}
            brands {id name}
            supplies(filter: $filter) {
              id
              brandId
              productId
              product {
                brand
                reference
                SKU
                dialColor
              }
              traderId
              supplier
              channelId
              currencyId
              price
              tax
              amount
              validTo
              validFrom
              msrp
              discount
              reference
              lastSeenAt
            }
          }`,
                variables
            }
        });

        search.register('supplies', data.supplies, {
            keys: [
                {
                    name: 'brand',
                    getFn: supply => supply
                        .product?.brand
                },
                {
                    name: 'reference',
                    getFn: supply => supply
                        .product?.reference
                        .replace(/[\s.-/]/g, '')
                },
                {
                    name: 'supplier',
                    getFn: supply => supply
                        .supplier
                }
            ]
        });

        return {
            currencies: keyBy(data.currencies, 'id'),
            supplies: keyBy(data.supplies, 'id'),
            brands: data.brands,
            traders: _.uniqBy(data.traders, 'name')
        }
    }
})

export const selectSupplyAll = createVibes({
    type: SELECT_SUPPLY_ALL_ROWS,
    processOptions: {
        successType: UPDATE_SUPPLIES_SELECTED
    },
    process({getState, action}) {
        const {payload} = action;
        const selected = getState().marketing.selected.slice(0);
        for (const p of payload) {
            if (!selected.includes(p)) {
                selected.push(p);
            }
        }
        return {selected}
    }
})

export const deselectSupplyAll = createVibes({
    type: DESELECT_SUPPLY_ALL_ROWS,
    processOptions: {
        successType: UPDATE_SUPPLIES_SELECTED
    },
    process({getState, action}) {
        const {payload} = action;
        const selected = getState().marketing.selected.slice(0);
        for (const p of payload) {
            const idx = selected.indexOf(p);
            selected.splice(idx, 1);
        }
        return {selected}
    }
});

export const selectSupply = createVibes({
    type: SELECT_SUPPLY_OFFER,
    processOptions: {
        successType: UPDATE_SUPPLIES_SELECTED
    },
    process({getState, action}) {
        const {payload} = action;
        const selected = getState().marketing.selected;

        const idx = selected.indexOf(payload);
        if (idx === -1) {
            return {selected: [...selected, payload]};
        } else {
            // These two line cannot combine;
            const ac = selected.slice(0);
            ac.splice(idx, 1);
            return {selected: ac};
        }
    }
})

export const supplyBatchApply = createVibes({
    type: BATCH_SUPPLY_OP_EXECUTE,
    throttle: 500,
    processOptions: {
        successType: BATCH_SUPPLY_OP_SUCCESS,
        failType: BATCH_SUPPLY_OP_FAILED
    },
    async process({getState, axios}) {
        const state = getState().marketing;
        const {operation, selected} = state;
        if (!operation) return;
        if (selected.length === 0) return;
        const variables = {
            input: selected
        };
        console.log(`${operation.toLowerCase()}Supplies`)
        const {data} = await axios.request({
            url: '/aprs',
            method: 'post',
            data: {
                query: `mutation batchSupplyOp($input: [Int]) {
                    ${operation.toLowerCase()}Supplies(input: $input)
                }`,
                variables
            }
        });
        return true
    }
})

export const filterSupplies = createVibes({
    type: [MARKETING_TYPE_AND_SEARCH, LOAD_SUPPLIES_SUCCESS],
    latest: true,
    processOptions: {
        successType: MARKETING_FILTER_APPLIED
    },
    async process({getState, search}) {
        const {search: payload, supplies, supplyIdx} = getState().marketing;
        if (payload.length === 0) {
            const kdx = Object.keys(supplies);
            if (supplyIdx.length < kdx.length) {
                return kdx;
            } else {
                return supplyIdx;
            }
        } else {
            const result = search.find('supplies',
                {reference: `^${payload.replace(/[\s.-/]/g, '')}`});
            return result.map(el => el.item.id);
        }
    }
})

export const loadProducts = createVibes({
    type: MARKETING_LOAD_PRODUCT,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: MARKETING_LOAD_PRODUCT_SUCCESS
    },
    async process({getState, axios, action}) {
        const brand = getState().marketing.instance.brand;
        const prefix = action.payload;
        const variables = {
            filter: {
                brandId: brand.id,
                search: prefix
            }
        }
        const {data} = await axios.request({
            url: '/aprs',
            method: 'post',
            data: {
                query: `query loadProduct($filter: InfoProductFilter) {
                    products:infoProducts(filter:$filter) {
                        id
                        reference
                        SKU
                        brand
                        dialColor
                    }
                }`,
                variables
            }
        });
        return data.products;
    }
})

export const updatePrice = createVibes({
    type: MARKETING_UPDATE_PRICE,
    latest: true,
    processOptions: {
        successType: MARKETING_UPDATE_PRICING
    },
    process({getState, action}) {
        const result = {};
        const {msrp, discount, price, amount, taxRate} = getState().marketing.instance;
        const field = action.path;
        if (msrp > 0) {
            // MSRP Mode
            result.msrp = msrp;
            result.discount = discount;
            result.amount = round(msrp * (1 - discount / 100), 2);
            if (taxRate > 0) {
                result.price = round(result.amount / (1 + taxRate / 100));
                result.tax = result.amount - result.price;
            } else {
                result.price = result.amount;
                result.tax = 0;
            }
        } else {
            result.msrp = 0;
            result.discount = 0;
            if (field === 'amount') {
                result.amount = amount;
                if (taxRate > 0) {
                    result.price = round(result.amount / (1 + taxRate / 100));
                    result.tax = result.amount - result.price;
                } else {
                    result.price = result.amount;
                    result.tax = 0;
                }
            } else {
                result.price = price;
                if (taxRate > 0) {
                    result.tax = round(result.price * (taxRate / 100));
                    result.amount = result.price + result.tax;
                } else {
                    result.amount = result.price;
                    result.tax = 0;
                }
            }
        }
        return result;
    }
})

export const saveSupplyOffer = createVibes({
    type: MARKETING_SAVE_INSTANCE,
    throttle: 500,
    latest: true,
    processOptions: {
        successType: MARKETING_SAVE_SUPPLY_SUCCESS,
        failType: MARKETING_SAVE_SUPPLY_FAILED,
    },
    async process({getState, axios}) {
        const {instance, section} = getState().marketing;
        const result = {};
        const payload = {
            channelId: section,
            traderId: instance.trader.id,
            brandId: instance.brand.id,
            productId: instance.product.id,
            currencyId: instance.currencyId,
            price: instance.price,
            tax: instance.tax,
            amount: instance.amount,
            msrp: instance.msrp,
            discount: instance.discount,
            quantity: instance.quantity,
            taxRate: instance.taxRate,
            validFor: instance.validFor
        }
        if (instance.id) {
            payload.id = instance.id;
            result.prev = instance.id;
        }
        const variables = {
            input: payload
        };
        const {data} = await axios.request({
            url: '/aprs',
            method: 'post',
            data: {
                query: `mutation saveSupply($input: SuppliesInput){
                    supply:saveSupplyOffer(input: $input) {
                        id
                        brandId
                        productId
                        product {
                            brand
                            reference
                            SKU
                            dialColor
                        }
                        traderId
                        supplier
                        channelId
                        currencyId
                        price
                        tax
                        amount
                        validTo
                        validFrom
                        msrp
                        discount
                        reference
                        lastSeenAt
                    }
                }`,
                variables
            }
        });
        result.instance = data.supply;
        return result;
    }
})

export const markingVibes = [
    loadSuppliesVibes,
    selectSupplyAll,
    deselectSupplyAll,
    selectSupply,
    supplyBatchApply,
    filterSupplies,
    loadProducts,
    updatePrice,
    saveSupplyOffer
];

