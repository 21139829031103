import {Box, Paper, Toolbar, Typography} from "@mui/material";
import {Outlet} from "react-router-dom";
export * from './dashboard';
export * from './supplies';

export function Marketing() {
    return <Paper sx={{
        flexGrow: 1,
        display: 'flex'
    }}>
        <Outlet/>
    </Paper>;
}