import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {MGLayout} from "./layout";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {KeyScanner} from '@cosmos/runes';
import {Provider} from "react-redux";
import {store} from './store';
import {BrowserRouter} from "react-router-dom";
import {App} from "./app";
import {LinkBehavior} from "./support";
import {customCreateTheme} from '@cosmos/runes';

const theme = customCreateTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Helvetica Neue',
            'Apple Color Emoji',
            'Arial',
            'sans-serif',
        ].join(','),
        fontSize: 12
    },
    spacing: 4,
    palette: {
        primary: {
            main: '#6495ed'
        },
        secondary: {
            main: '#ffb7c5',
        },
    },
    common: {
        black: '#282828',
        white: '#fffafa'
    },
    components: {
        MuiLink: {
            defaultProps: {component: LinkBehavior}
        }
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <KeyScanner header={'\\(DI\\)|\\)DI\\='}>
        <Provider store={store}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <MGLayout/>
                    <CssBaseline/>
                    <App/>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    </KeyScanner>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
