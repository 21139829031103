import {
    Dialog, DialogContent,
    DialogTitle,
    LinearProgress,
    useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/material";
import {useSelector} from "react-redux";

export function Splash() {
    const level = useSelector(state => state.system.level);
    const message = useSelector(state => state.system.message);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            open={level < 10}
            fullScreen={fullScreen}
            sx={{bgcolor: "common.white"}}
        >
            <DialogTitle
                sx={{
                    fontSize: '200%',
                    color: 'common.white',
                    bgcolor: 'primary.dark',
                    width: '36rem'
                }}>
                Cosmos System
            </DialogTitle>
            <LinearProgress color='secondary' variant="indeterminate"/>
            <DialogContent
                sx={{
                    fontSize: '150%',
                    height: '12rem'
                }}
            >
                {message && message}
            </DialogContent>
        </Dialog>
    );
}