import {createReducer} from "@reduxjs/toolkit";
import {APP_STATE_INIT, APP_STATE_READY} from "../actions/system";
import {TOKEN_KEY, authenticator, AUTH_METHOD_REFRESH, AUTH_ATTEMPT_SUCCESS} from '@cosmos/runes';
import jwtDecode from 'jwt-decode';
import {createVibes} from "@cosmos/vibes";
import {catchError, lastValueFrom, of} from "rxjs";
import {map, tap} from 'rxjs/operators'

export const systemReducer = createReducer(
    {
        level: 10,
        message: null
    },
    builder => {
        builder.addCase(APP_STATE_INIT, (state) => {
            state.level = 0;
            state.message = 'Initializing System ...';
        }).addCase(APP_STATE_READY, (state) => {
            state.level = 10
            state.message = 'System Ready!!';
        })
    })

export const systemInitVibes = createVibes({
    type: APP_STATE_INIT,
    async process({}, dispatch, done) {
        const key = localStorage.getItem(TOKEN_KEY);
        if (key) {
            try {
                const token = await lastValueFrom(authenticator(AUTH_METHOD_REFRESH, key));
                if(token) {
                    localStorage.setItem(TOKEN_KEY, token);
                    dispatch({type:AUTH_ATTEMPT_SUCCESS, payload: {token, user: jwtDecode(token)}});
                }
            } catch(e) {
                localStorage.removeItem(TOKEN_KEY);
            }
        }
        dispatch({type:APP_STATE_READY});
        done();
    }
})