import React from 'react';
import {Box, lighten, Link, MenuItem, MenuList} from "@mui/material";
import {features} from '../system';
import {useLocation} from "react-router-dom";


export function SideMenu() {
    const location = useLocation();
    const idx = location.pathname.indexOf('/', 1);
    const path = (idx > -1) ? location.pathname.substr(0, idx) : location.pathname;
    const feature = features.find(f => f.path === path);
    return <Box sx={[{
        display: {xs: 'none', md: 'flex'},
        position: 'sticky',
        left: 0,
        top: 0,
        width: '3em',
        zIndex: 1000,
        pt: 10
    }, theme => ({
        backgroundColor: lighten(theme.palette.primary.light, 0.7)
    })]}>
        <MenuList>
            {feature &&
                feature.children.map(s => (
                    <MenuItem key={s.name} sx={{
                        m: 2, p: 0, display: 'flex',
                        overflow: 'hidden',
                        width: '2rem',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            width: 'auto',
                        }
                    }}>
                        <Link to={`${feature.path}${s.path}`}
                              sx={[{
                                  flexGrow: 1,
                                  p: '0.5rem 1rem 0.5rem 0.5rem',
                                  borderRadius: '1.5rem',
                                  textTransform: 'uppercase',
                                  color: 'common.black',
                                  display: 'flex',
                                  alignItems: 'center',
                                  '&:hover': {
                                      backgroundColor: 'primary.light',
                                      textDecoration: 'none',
                                  }
                              }, theme => ({
                                  backgroundColor: lighten(theme.palette.primary.light, 0.5),
                                  '&.active': {
                                      backgroundColor: lighten(theme.palette.secondary.main, 0.1),
                                  }
                              })]}>
                            <Box sx={{
                                mr: '0.5rem',
                                width: '1.5rem',
                                height: '1.5rem',
                                borderRadius: '50%',
                                bgcolor: 'primary.light',
                                position: 'relative',
                            }}><Box sx={{
                                position: 'absolute',
                                transform: 'translate(-50%, -50%)',
                                top: '50%',
                                left: '50%'
                            }}>
                                {(s.icon) ? s.icon : s.name.charAt(0).toUpperCase()}
                            </Box>
                            </Box>
                            {s.name}
                        </Link>
                    </MenuItem>
                ))
            }
        </MenuList>
    </Box>
}
